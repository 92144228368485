import React, { useState, useEffect } from "react";
import "./ModeStep.scss";
import Step from "./../../UI/Step/Step";
import Card from "./../../UI/Card/Card";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const ModeStep = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!props.mode) {
      setIsOpen(true);
    }
  }, [props.mode]);

  const selectModeHandler = (newMode) => {
    props.select(newMode);
    setIsOpen(false);
  };

  return (
    <Step
      title={"Scegli la modalità"}
      done={props.mode ? true : false}
      open={isOpen && !props.status ? true : false}
      toggle={() => setIsOpen(!isOpen)}
    >
      {isOpen && !props.status ? (
        <div className="ModeStep">
          <Card
            icon={faUserCircle}
            click={() => selectModeHandler(1)}
            selected={props.mode === 1 ? true : false}
          >
            <h3>Incontra un esperto</h3>
            <p>
              Sei indeciso? Prenota un appuntamento personale in negozio con uno
              dei nostri esperti
            </p>
          </Card>
          <Card
            icon={faVideo}
            click={() => selectModeHandler(2)}
            selected={props.mode === 2 ? true : false}
          >
            <h3>Video-call</h3>
            <p>Prenota una video-call con uno dei nostri esperti in negozio</p>
          </Card>
        </div>
      ) : null}
    </Step>
  );
};

export default ModeStep;
