import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./StoreStep.scss";
import Step from "./../../UI/Step/Step";
import Store from "./Store/Store";
import Spinner from "./../../UI/Spinner/Spinner";
import * as actions from "./../../../store/actions/index";
import Card from "./../../UI/Card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const StoreStep = (props) => {
  const storeList = useSelector((state) => state.store.storeList);
  const loading = useSelector((state) => state.store.loading);
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ lat: null, long: null });
  const [cap, setCap] = useState({ value: "" });

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.mode && !props.store) {
      setIsOpen(true);
    }
  }, [props.mode]);

  const selectStoreHandler = (newStore) => {
    props.select(newStore);
    setIsOpen(false);
  };

  const deselectStoreHandler = () => {
    dispatch(actions.deselectStoreList());
  };

  const getStoreListHandler = (ev, type) => {
    ev.preventDefault();
    if (type === "geo") {
      if (position.lat && position.long) {
        dispatch(actions.getStoreList(0, position.lat, position.long));
      } else {
        navigator.geolocation.getCurrentPosition(geoSuccess, () => {});
      }
    } else if (type === "cap" && cap.value) {
      dispatch(actions.getStoreList(cap.value));
      setCap({ value: "" });
    }
  };

  const geoSuccess = (pos) => {
    setPosition({ lat: pos.coords.latitude, long: pos.coords.longitude });
    dispatch(actions.getStoreList(0, position.lat, position.long));
  };

  return (
    <Step
      title={"Scegli dove"}
      toggle={() => setIsOpen(!isOpen)}
      done={props.store ? true : false}
      open={props.mode ? (isOpen && !props.status ? true : false) : null}
    >
      {isOpen && !props.status && props.mode ? (
        <div className="StoreStep">
          {loading ? (
            <Spinner />
          ) : storeList.length > 0 ? (
            <>
              <div className="store-back">
                <div
                  className="c-pointer"
                  onClick={() => deselectStoreHandler()}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>Indietro</span>
                </div>
              </div>
              <p>Negozi vicino a te</p>
              {storeList.map((el, index) => (
                <Store
                  key={index}
                  el={el}
                  store={props.store}
                  click={(newStore) => selectStoreHandler(newStore)}
                />
              ))}
            </>
          ) : (
            <div className="searchStore">
              <Card
                icon={faCrosshairs}
                className="storePosition"
                click={(ev) => getStoreListHandler(ev, "geo")}
              >
                <h3>In base alla tua posizione</h3>
              </Card>
              <form
                className={`searchByCap ${cap.value ? "complete" : ""}`}
                onSubmit={(ev) => getStoreListHandler(ev, "cap")}
              >
                <input
                  type="number"
                  id="cap"
                  value={cap.value || ""}
                  onChange={(ev) => setCap({ value: ev.target.value })}
                  placeholder="Oppure digital il tuo CAP"
                />
                <button className="sbc-submit" />
                <label htmlFor="cap">Oppure digita il tuo CAP</label>
              </form>
            </div>
          )}
        </div>
      ) : null}
    </Step>
  );
};

export default StoreStep;
