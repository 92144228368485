import React from "react";
import "./Store.scss";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import Card from "./../../../UI/Card/Card";

const Store = (props) => {
  return (
    <div className="Store">
      <Card
        icon={faMapMarker}
        key={props.el.id}
        selected={props.store?.id === props.el.id ? true : false}
        click={() => props.click(props.el)}
      >
        <h3>{props.el.name}</h3>
        <p>{props.el.address}</p>
      </Card>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="maps-link"
        href={`http://maps.apple.com/?daddr=${props.el.latitude},${props.el.longitude}`}
      >
        Apri su Mappe
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="maps-link"
        href={`http://www.google.com/maps/place/${props.el.latitude},${props.el.longitude}`}
      >
        Apri su Google Maps
      </a>
    </div>
  );
};

export default Store;
