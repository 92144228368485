export const SELECT_MODE = "SELECT_MODE";
export const SELECT_STORE = "SELECT_STORE";
export const DESELECT_STORE = "DESELECT_STORE";
export const SELECT_EXPERT = "SELECT_EXPERT";
export const DESELECT_EXPERT = "DESELECT_EXPERT";
export const SELECT_SLOT = "SELECT_SLOT";
export const SELECT_PLATFORM = "SELECT_PLATFORM";
export const DESELECT_PLATFORM = "DESELECT_PLATFORM";
export const NEW_BOOKING = "NEW_BOOKING";
export const GET_STORE_LIST = "GET_STORE_LIST";
export const GET_EXPERT_LIST = "GET_EXPERT_LIST";
export const GET_EXPERT_AVAILABILITY = "GET_EXPERT_AVAILABILITY";
export const STORE_REQUEST_START = "STORE_REQUEST_START";
export const EXPERT_REQUEST_START = "EXPERT_REQUEST_START";
export const AVAILABILITY_REQUEST_START = "AVAILABILITY_REQUEST_START";
export const BOOKING_REQUEST_START = "BOOKING_REQUEST_START";
export const DESELECT_STORE_LIST = "DESELECT_STORE_LIST";
