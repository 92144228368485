import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getExpertList = (store_id) => {
  return (dispatch) => {
    dispatch(expertRequestStart());
    axios
      .get("/shop-assistants", { params: { store_id } })
      .then((res) => {
        dispatch(getExpertListSuccess(res.data.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getExpertListSuccess = (data) => {
  return {
    type: actionTypes.GET_EXPERT_LIST,
    expertList: data,
  };
};

export const getExpertAvailability = (expert_id, week_number, year) => {
  return (dispatch) => {
    dispatch(availabilityRequestStart());
    axios
      .get(`/shop-assistants/${expert_id}/weekly-slots`, {
        params: { week_number, year },
      })
      .then((res) => {
        dispatch(getExpertAvailabilitySuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getExpertAvailabilitySuccess = (data) => {
  return {
    type: actionTypes.GET_EXPERT_AVAILABILITY,
    availability: data,
  };
};

export const selectExpert = (newExpertId) => {
  return {
    type: actionTypes.SELECT_EXPERT,
    expert: newExpertId,
  };
};

export const deselectExpert = () => {
  return {
    type: actionTypes.DESELECT_EXPERT,
  };
};

export const selectSlot = (day, newSlot) => {
  return {
    type: actionTypes.SELECT_SLOT,
    day: day,
    slot: newSlot,
  };
};

export const expertRequestStart = () => {
  return {
    type: actionTypes.EXPERT_REQUEST_START,
  };
};

export const availabilityRequestStart = () => {
  return {
    type: actionTypes.AVAILABILITY_REQUEST_START,
  };
};
