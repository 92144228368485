import React, { useState, useEffect } from "react";
import "./RegisterStep.scss";
import Step from "./../../UI/Step/Step";
import Button from "./../../UI/Button/Button";
import Spinner from "./../../UI/Spinner/Spinner";
import Checkbox from "./../../UI/Checkbox/Checkbox";

const RegisterStep = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState({ value: "enea" });
  const [surname, setSurname] = useState({ value: "felicetti" });
  const [email, setEmail] = useState({ value: "eneafelicetti@gmail.com" });
  const [phone, setPhone] = useState({ value: "3487857563" });
  const [terms, setTerms] = useState({ value: false, error: false });
  const [privacy, setPrivacy] = useState({ value: false, error: false });
  const [marketing, setMarketing] = useState({ value: false });

  useEffect(() => {
    if (props.mode === 2) {
      if (props.platform) {
        setIsOpen(true);
      }
    } else {
      if (props.slot) {
        setIsOpen(true);
      }
    }
  }, [props.slot, props.mode, props.platform]);

  let checkMode;

  if (props.mode === 2) {
    checkMode = isOpen && !props.status && props.platform;
  } else {
    checkMode = isOpen && !props.status && props.slot;
  }

  useEffect(() => {
    if (props.status) {
      setIsOpen(false);
    }
  }, [props.status]);

  const completeBookHandler = (ev) => {
    ev.preventDefault();

    if (!terms.value || !privacy.value) {
      if (!terms.value) {
        setTerms({ ...terms, error: true });
      }
      if (!privacy.value) {
        setPrivacy({ ...privacy, error: true });
      }
    } else {
      props.registration({
        name: name.value,
        surname: surname.value,
        email: email.value,
        phone: phone.value,
        terms: terms.value,
        privacy: privacy.value,
        marketing: marketing.value,
      });
    }
  };

  return (
    <Step
      title={"Registrati"}
      toggle={() => setIsOpen(!isOpen)}
      done={props.status ? true : false}
      open={
        props.mode === 2
          ? props.platform
          : props.slot
          ? isOpen && !props.status
            ? true
            : false
          : null
      }
    >
      {props.loading ? (
        <Spinner />
      ) : checkMode ? (
        <form
          className="RegisterStep"
          onSubmit={(ev) => completeBookHandler(ev)}
        >
          <div className={`form-element ${name.value ? "complete" : ""}`}>
            <input
              id="name"
              type="text"
              placeholder="Nome"
              value={name.value || ""}
              onChange={(ev) => setName({ value: ev.target.value })}
            />
            <label htmlFor="name">Nome</label>
          </div>
          <div className={`form-element ${surname.value ? "complete" : ""}`}>
            <input
              id="surname"
              type="text"
              placeholder="Cognome"
              value={surname.value || ""}
              onChange={(ev) => setSurname({ value: ev.target.value })}
            />
            <label htmlFor="surname">Cognome</label>
          </div>
          <div className={`form-element ${email.value ? "complete" : ""}`}>
            <input
              id="email"
              type="email"
              placeholder="Email"
              value={email.value || ""}
              onChange={(ev) => setEmail({ value: ev.target.value })}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className={`form-element ${phone.value ? "complete" : ""}`}>
            <input
              id="phone"
              type="number"
              placeholder="Numero di telefono"
              value={phone.value || ""}
              onChange={(ev) => setPhone({ value: ev.target.value })}
            />
            <label htmlFor="phone">Numero di Telefono</label>
          </div>
          <Checkbox
            id={"terms"}
            error={terms.error ? true : false}
            checked={terms.value}
            change={() => setTerms({ value: !terms.value, error: false })}
            label={"i termini e le condizioni"}
          />
          <Checkbox
            id={"privacy"}
            error={privacy.error ? true : false}
            checked={privacy.value}
            change={() => setPrivacy({ value: !privacy.value, error: false })}
            label={"la privacy policy"}
          />
          <Checkbox
            id={"marketing"}
            checked={marketing.value}
            change={() => setMarketing({ value: !marketing.value })}
            label={"il trattamento dei miei dati"}
          />
          <Button
            disabled={
              name.value && surname.value && email.value && phone.value
                ? false
                : true
            }
          >
            Conferma la prenotazione
          </Button>
        </form>
      ) : null}
    </Step>
  );
};

export default RegisterStep;
