import React from "react";
import "./Checkbox.scss";

const Checkbox = (props) => {
  return (
    <div className="Checkbox">
      <input
        type="checkbox"
        className={`${props.error ? "error" : "null"}`}
        id={props.id}
        onChange={props.change}
        checked={props.checked}
      />
      <label htmlFor={props.id}>Accetto</label>
      <a href={props.link}>{props.label}</a>
    </div>
  );
};

export default Checkbox;
