import React from "react";
import "./AvailableSlot.scss";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Card from "./../../../../../UI/Card/Card";
import { AvailableFormat } from "./../../../../../Helpers/DateFormat";

const AvailableSlot = (props) => {
  return (
    <div className="AvailableSlot">
      {props.slotAvail.map((el, index) => {
        if (el.free) {
          return (
            <Card
              key={index}
              icon={faCalendar}
              click={() => props.click(props.day, el)}
              selected={
                props.selectDay?.getTime() === props.day?.getTime() &&
                props.slot.start_hour === el.start_hour
                  ? true
                  : false
              }
            >
              <h3>{AvailableFormat(props.day)}</h3>
              <p>{`dalle ${el.start_hour.slice(0, 5)} alle ${el.end_hour.slice(0, 5)}`}</p>
            </Card>
          );
        }
      })}
    </div>
  );
};

export default AvailableSlot;
