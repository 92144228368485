import * as actionTypes from "./actionTypes";

export const selectPlatform = (newPlatform) => {
  return {
    type: actionTypes.SELECT_PLATFORM,
    platform: newPlatform,
  };
};

export const deselectPlatform = () => {
  return {
    type: actionTypes.DESELECT_PLATFORM,
  };
};
