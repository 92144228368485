import * as actionTypes from "../actions/actionTypes";

const initialState = {
  platform: null,
};

const platformReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_PLATFORM:
      return {
        platform: action.platform,
      };
    case actionTypes.DESELECT_PLATFORM:
      return {
        platform: null,
      };

    default:
      return state;
  }
};

export default platformReducer;
