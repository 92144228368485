import React from "react";
import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
  return (
    <div className="Header">
      <h3>Ritira o scegli in negozio</h3>
      <div className="close" onClick={props.close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

export default Header;
