import * as actionTypes from "../actions/actionTypes";

const initialState = {
  status: false,
  loading: false,
  data: null,
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEW_BOOKING:
      return {
        ...state,
        status: true,
        loading: false,
        data: action.data,
      };

    case actionTypes.BOOKING_REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default registerReducer;
