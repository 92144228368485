import React from "react";
import "./ResumeBooking.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarker,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { AvailableFormat } from "./../../Helpers/DateFormat";

const ResumeBooking = (props) => {
  return (
    <div className="ResumeBooking">
      <h1>Richiesta inviata</h1>
      <h3>Riceverai una conferma della tua prenotazione via mail e sms</h3>
      <div className="resumeBody">
        <ul>
          <li>
            <FontAwesomeIcon icon={faCalendar} />
            <div className="resumeLiInfo">
              <h3>{props.day ? AvailableFormat(props.day) : null}</h3>
              <p>{`dalle ${props.slot?.start_hour.slice(0, 5)} alle ${props.slot?.end_hour.slice(0, 5)}`}</p>
            </div>
          </li>
          <li>
            <div className="resumeProfile">
              {props.expert?.profile_image ? (
                <img src={props.expert?.profile_image} alt="" />
              ) : null}
            </div>
            <div className="resumeLiInfo">
              <span>con</span> <h3>{props.expert?.name}</h3>
              <p className="expert-on">
                esperto in <strong>{props.expert?.job_title}</strong>
              </p>
            </div>
          </li>
          <li>
            <FontAwesomeIcon icon={faMapMarker} />
            <div className="resumeLiInfo">
              <h3>{props.store?.name}</h3>
              <p>{props.store?.address}</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="resumeBack" onClick={props.close}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <strong>Torna all'ecommerce</strong>
      </div>
    </div>
  );
};

export default ResumeBooking;
