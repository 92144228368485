import React from "react";
import "./Step.scss";

const Card = (props) => {
  return (
    <div
      className={`Step ${props.done ? "done" : ""} ${props.open ? "open" : ""}`}
    >
      <h2 className="step-title" onClick={props.toggle}>
        {props.title}
      </h2>
      {props.children ? (
        <div className="step-content">{props.children}</div>
      ) : null}
    </div>
  );
};

export default Card;
