import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ExpertStep.scss";
import Step from "./../../UI/Step/Step";
import * as actions from "./../../../store/actions/index";
import Expert from "./Expert/Expert";
import { getWeekNumber } from "./../../Helpers/DateFormat";
import Spinner from "../../UI/Spinner/Spinner";

const ExpertStep = (props) => {
  const expert = useSelector((state) => state.expert.expert);
  const expertList = useSelector((state) => state.expert.expertList);
  const expertLoading = useSelector((state) => state.expert.expertLoading);
  const availability = useSelector((state) => state.expert.availability);

  const [isOpen, setIsOpen] = useState(false);
  const [isSelectable, setIsSelectable] = useState(false);
  const [currentWeek, setCurrentWeek] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.store) {
      setIsOpen(true);
      setIsSelectable(true);
      dispatch(actions.getExpertList(props.store.id));
    }
  }, [props.store]);

  useEffect(() => {
    if (props.slot) {
      setIsOpen(false);
    }
  }, [props.slot]);

  useEffect(() => {
    let d = new Date();
    let newD = getWeekNumber(d);
    setCurrentWeek(newD);

    if (expert) {
      setIsSelectable(false);
    }
  }, []);

  const selectExpertHandler = (newExpert) => {
    if (newExpert.id !== expert?.id) {
      dispatch(actions.selectExpert(newExpert));
      dispatch(
        actions.getExpertAvailability(
          newExpert.id,
          currentWeek[1],
          currentWeek[0]
        )
      );
    }
    setIsSelectable(false);
  };

  const deselectExpertHandler = () => {
    dispatch(actions.deselectExpert());
    setIsSelectable(true);
    if (props.mode === 2 && props.platform) {
      dispatch(actions.deselectPlatform());
    }
  };

  return (
    <Step
      title={"Scegli quando"}
      toggle={() => setIsOpen(!isOpen)}
      done={props.slot ? true : false}
      open={props.store ? (isOpen && !props.status ? true : false) : null}
    >
      {isOpen && !props.status && props.store ? (
        <div className="ExpertStep">
          {expertLoading ? (
            <Spinner />
          ) : (
            <>
              {isSelectable ? (
                <p>
                  Seleziona uno dei nostri esperti per verificare la
                  disponibilità
                </p>
              ) : null}
              {expertList.map((el, index) => (
                <Expert
                  key={index}
                  el={el}
                  expert={expert}
                  availability={availability}
                  isSelectable={isSelectable}
                  currentWeek={currentWeek}
                  click={() => selectExpertHandler(el)}
                  clickSlot={() => setIsOpen(false)}
                  deselect={() => deselectExpertHandler()}
                />
              ))}
            </>
          )}
        </div>
      ) : null}
    </Step>
  );
};

export default ExpertStep;
