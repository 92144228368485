import * as actionTypes from "../actions/actionTypes";

const initialState = {
  mode: null,
};

const modeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_MODE:
      return {
        mode: action.mode,
      };

    default:
      return state;
  }
};

export default modeReducer;
