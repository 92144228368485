let today = new Date();
today.setHours(0, 0, 0, 0);

const days = [
  "Domenica",
  "Lunedì",
  "Martedì",
  "Mercoledì",
  "Giovedì",
  "Venerdì",
  "Sabato",
];

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export const AvailableFormat = (date) => {
  new Date(date);
  if (date.getTime() === today.getTime()) {
    return "Oggi";
  } else {
    let final = `${days[date.getDay()]} ${date.getDate()} ${
      months[date.getMonth()]
    }`;
    return final;
  }
};

export const dayLong = (dayNumber) => {
  return days[dayNumber];
};

export const MonthLong = (monthNumber) => {
  return months[monthNumber];
};

export const getHourFormat = (date) => {
  let d = new Date(date);

  return `${("0" + d.getHours()).slice(-2)}:${("0" + d.getSeconds()).slice(-2)}`;
};

export const getWeekNumber = (d) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return [d.getUTCFullYear(), weekNo];
};

export const getDateOfISOWeek = (w, y) => {
  let simple = new Date(y, 0, 1 + (w - 1) * 7);
  let dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

export const addHourToDate = (d, hour) => {
  d = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
    "0" + d.getDate()
  ).slice(-2)} ${hour}`;
  return d;
};
