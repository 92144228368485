import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getStoreList = (postal_code, lat = 0, long = 0) => {
  return (dispatch) => {
    dispatch(storeRequestStart());
    let opt;
    if (postal_code !== 0) {
      opt = { postal_code: postal_code };
    } else if (lat !== 0 && long !== 0) {
      opt = { latitude: lat, longitude: long };
    }
    axios
      .get("/stores", { params: { ...opt } })
      .then((res) => {
        dispatch(getStoreListSuccess(res.data.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getStoreListSuccess = (data) => {
  return {
    type: actionTypes.GET_STORE_LIST,
    storeList: data,
  };
};

export const deselectStoreList = () => {
  return {
    type: actionTypes.DESELECT_STORE_LIST,
  };
};

export const selectStore = (newStoreId) => {
  return {
    type: actionTypes.SELECT_STORE,
    store: newStoreId,
  };
};

export const deselectStore = () => {
  return {
    type: actionTypes.DESELECT_STORE,
  };
};

export const storeRequestStart = () => {
  return {
    type: actionTypes.STORE_REQUEST_START,
  };
};
