import * as actionTypes from "./actionTypes";
import { addHourToDate } from "./../../components/Helpers/DateFormat";
import axios from "axios";

export const newBooking = (data) => {
  let date_start = addHourToDate(data.day, data.start_hour);
  let date_end = addHourToDate(data.day, data.end_hour);
  return (dispatch) => {
    dispatch(bookingRequestStart());
    axios
      .post("/appointments", {
        store_id: data.storeId,
        shop_assistant_id: data.expertId,
        client_name: data.name,
        client_surname: data.surname,
        client_preferred_contact_type: "email",
        client_email: data.email,
        client_phone: data.phone,
        client_privacy_1: data.terms,
        client_privacy_2: data.privacy,
        client_privacy_3: data.marketing,
        referrer_url: "https://www.fabrico.digital",
        appointment_date_time_start: date_start,
        appointment_date_time_end: date_end,
      })
      .then((res) => {
        dispatch(newBookingSuccess());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const newBookingSuccess = (data) => {
  return {
    type: actionTypes.NEW_BOOKING,
    data: data,
  };
};

export const bookingRequestStart = () => {
  return {
    type: actionTypes.BOOKING_REQUEST_START,
  };
};
