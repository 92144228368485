import React from "react";
import ReactDOM from "react-dom";
import "./style.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import axios from "axios";
import modeReducer from "./store/reducers/mode";
import storeReducer from "./store/reducers/store";
import expertReducer from "./store/reducers/expert";
import registerReducer from "./store/reducers/register";
import platformReducer from "./store/reducers/platform";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  mode: modeReducer,
  store: storeReducer,
  expert: expertReducer,
  register: registerReducer,
  platform: platformReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

axios.defaults.baseURL = "https://gasbackoffice.fabricandum.com/api/v1/";

axios.interceptors.request.use(function (config) {
  config.headers.Accept = "application/json";

  return config;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
