import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./MainModal.scss";
import Header from "./Header/Header";
import ModeStep from "./ModeStep/ModeStep";
import StoreStep from "./StoreStep/StoreStep";
import ExpertStep from "./ExpertStep/ExpertStep";
import RegisterStep from "./RegisterStep/RegisterStep";
import PlatformStep from "./PlatformStep/PlatformStep";
import ResumeBooking from "./ResumeBooking/ResumeBooking";
import * as actions from "./../../store/actions/index";

const MainModal = (props) => {
  const mode = useSelector((state) => state.mode.mode);
  const store = useSelector((state) => state.store.store);
  const day = useSelector((state) => state.expert.day);
  const slot = useSelector((state) => state.expert.slot);
  const expert = useSelector((state) => state.expert.expert);
  const platform = useSelector((state) => state.platform.platform);
  const registerStatus = useSelector((state) => state.register.status);
  const registerLoading = useSelector((state) => state.register.loading);

  const dispatch = useDispatch();

  const selectMode = (newMode) => {
    if (mode) {
      if (mode !== newMode) {
        dispatch(actions.selectMode(newMode));
        if (store) {
          dispatch(actions.deselectStore());
        }
        if (expert) {
          dispatch(actions.deselectExpert());
        }
        if (mode === 2 && platform) {
          dispatch(actions.deselectPlatform());
        }
      }
    } else {
      dispatch(actions.selectMode(newMode));
    }
  };

  const selectStore = (newStore) => {
    if (store?.id !== newStore.id) {
      dispatch(actions.selectStore(newStore));

      if (expert) {
        dispatch(actions.deselectExpert());
      }
      if (mode === 2 && platform) {
        dispatch(actions.deselectPlatform());
      }
    }
  };

  const selectPlatform = (newPlatform) => {
    if (platform !== newPlatform) {
      dispatch(actions.selectPlatform(newPlatform));
    }
  };

  const newBooking = (data) => {
    dispatch(
      actions.newBooking({
        ...data,
        storeId: store.id,
        expertId: expert.id,
        day: day,
        start_hour: slot.start_hour,
        end_hour: slot.end_hour,
      })
    );
  };

  return (
    <div className="MainModal">
      <Header close={props.close} />
      <div className="modal-step">
        <ModeStep
          status={registerStatus}
          mode={mode}
          select={(newMode) => selectMode(newMode)}
        />
        <StoreStep
          status={registerStatus}
          mode={mode}
          store={store}
          select={(newStore) => selectStore(newStore)}
        />
        <ExpertStep
          status={registerStatus}
          store={store}
          slot={slot}
          mode={mode}
          platform={platform}
        />
        {mode === 2 ? (
          <PlatformStep
            mode={mode}
            platform={platform}
            status={registerStatus}
            slot={slot}
            selected={(newPlatform) => {
              selectPlatform(newPlatform);
            }}
          />
        ) : null}
        <RegisterStep
          mode={mode}
          slot={slot}
          status={registerStatus}
          platform={platform}
          loading={registerLoading}
          registration={(data) => newBooking(data)}
        />
      </div>
      {registerStatus ? (
        <ResumeBooking
          store={store}
          day={day}
          slot={slot}
          expert={expert}
          close={props.close}
        />
      ) : null}
    </div>
  );
};

export default MainModal;
