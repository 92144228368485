import React, { useState, useEffect } from "react";
import "./PlatformStep.scss";
import Step from "./../../UI/Step/Step";
import Card from "./../../UI/Card/Card";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const PlatformStep = (props) => {
  useEffect(() => {
    if (props.slot) {
      setIsOpen(true);
    }
  }, [props.slot]);

  const [isOpen, setIsOpen] = useState(false);

  const selectPlatformHandler = (newPlatform) => {
    props.selected(newPlatform);
    setIsOpen(false);
  };

  return (
    <Step
      title={"Scegli il tipo di contatto"}
      toggle={() => setIsOpen(!isOpen)}
      done={props.platform ? true : false}
      open={
        props.mode === 2 && props.slot
          ? isOpen && !props.status
            ? true
            : false
          : null
      }
    >
      {isOpen && !props.status && props.mode === 2 && props.slot ? (
        <div className="PlatformStep">
          <p>
            Tramite quale piattaforma preferisci essere contattato per la
            video-call?
          </p>
          <Card
            icon={faWhatsapp}
            click={() => selectPlatformHandler("whatsapp")}
            selected={props.platform === "whatsapp" ? true : false}
          >
            <h3>Whatsapp</h3>
            <p>
              Ti chiameremo noi tramite Whatsapp al numero che hai lasciato.
            </p>
          </Card>
          <Card
            icon={faVideo}
            click={() => selectPlatformHandler("zoom")}
            selected={props.platform === "zoom" ? true : false}
          >
            <h3>Zoom</h3>
            <p>
              Ti manderemo un link per accedere alla videocall tramite Zoom.
            </p>
          </Card>
        </div>
      ) : null}
    </Step>
  );
};

export default PlatformStep;
