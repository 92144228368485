import React from "react";
import "./Card.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = (props) => {
  return (
    <>
      {props.children ? (
        <div
          className={`Card ${props.selected ? "selected" : ""}`}
          onClick={props.click}
        >
          <div className="card-icon">
            <FontAwesomeIcon icon={props.icon} />
          </div>
          <div className="card-content">{props.children}</div>
        </div>
      ) : null}
    </>
  );
};

export default Card;
