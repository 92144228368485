import * as actionTypes from "../actions/actionTypes";

const initialState = {
  day: null,
  slot: null,
  expert: false,
  expertList: [],
  expertLoading: false,
  availability: {},
  availabilityLoading: false,
};

const expertReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXPERT_LIST:
      return {
        ...state,
        expertList: [...action.expertList],
        expertLoading: false,
      };

    case actionTypes.GET_EXPERT_AVAILABILITY:
      return {
        ...state,
        availability: { ...action.availability },
        availabilityLoading: false,
      };

    case actionTypes.SELECT_EXPERT:
      return {
        ...state,
        expert: action.expert,
      };

    case actionTypes.DESELECT_EXPERT:
      return {
        ...state,
        expert: false,
        day: null,
        slot: null,
        availability: {},
      };

    case actionTypes.SELECT_SLOT:
      return {
        ...state,
        day: action.day,
        slot: action.slot,
      };

    case actionTypes.EXPERT_REQUEST_START:
      return {
        ...state,
        expertLoading: true,
      };

    case actionTypes.AVAILABILITY_REQUEST_START:
      return {
        ...state,
        availabilityLoading: true,
      };

    default:
      return state;
  }
};

export default expertReducer;
