import * as actionTypes from "../actions/actionTypes";

const initialState = {
  store: null,
  storeList: [],
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_STORE:
      return {
        ...state,
        store: action.store,
      };
    case actionTypes.GET_STORE_LIST:
      return {
        ...state,
        storeList: [...action.storeList],
        loading: false,
      };
    case actionTypes.DESELECT_STORE_LIST:
      return {
        store: null,
        storeList: [],
      };
    case actionTypes.DESELECT_STORE:
      return {
        ...state,
        store: null,
      };
    case actionTypes.STORE_REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default storeReducer;
