import React, { useState } from "react";
import OpenModalButton from "./components/OpenModalButton/OpenModalButton";
import MainModal from "./components/MainModal/MainModal";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {isModalOpen ? (
        <MainModal close={() => setIsModalOpen(false)} />
      ) : (
        <OpenModalButton click={() => setIsModalOpen(true)} />
      )}
    </>
  );
};

export default App;
