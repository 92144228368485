import React, { useEffect, useState } from "react";
import "./Calendar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { MonthLong } from "./../../../../../Helpers/DateFormat";

const Calendar = (props) => {
  const [calendar, setCalendar] = useState([]);

  useEffect(() => {
    if (props.firstDayOfWeek) {
      let date = props.firstDayOfWeek;
      for (let i = 0; i < 7; i++) {
        let upDate = new Date(date);
        upDate.setDate(upDate.getDate() + i);
        setCalendar((calendar) => [...calendar, upDate]);
      }
    }
  }, [props.firstDayOfWeek]);

  // Change day selected
  const dayActiveHandler = (newDayActive) => {
    props.changeDayActive(newDayActive);
  };

  return (
    <div className="Calendar">
      <div className="row-month">
        <FontAwesomeIcon icon={faChevronLeft} />
        <h3>
          {`${calendar[0]?.getDate()} - ${calendar[6]?.getDate()} ${MonthLong([
            calendar[0]?.getMonth(),
          ])} ${calendar[0]?.getFullYear()}`}
        </h3>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      <div className="row-days">
        {calendar.map((el, index) => {
          let days = ["D", "L", "M", "M", "G", "V", "S"];
          let dayName = days[el.getDay()];
          return (
            <div
              key={index}
              className={`col-day ${
                el.getDate() === props.dayActive ? "active" : ""
              }`}
            >
              <h3>{dayName}</h3>
              {el.getTime() >= props.today.getTime() ? (
                <span
                  className="c-pointer"
                  onClick={() => dayActiveHandler(el)}
                >
                  {el.getDate()}
                </span>
              ) : (
                <span className="day-off">{el.getDate()}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
