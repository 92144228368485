import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ExpertBook.scss";
import { getDateOfISOWeek } from "./../../../../Helpers/DateFormat";
import Calendar from "./Calendar/Calendar";
import AvailableSlot from "./AvailableSlot/AvailableSlot";
import * as actions from "./../../../../../store/actions/index";
import Spinner from "../../../../UI/Spinner/Spinner";

const ExpertBook = (props) => {
  const slot = useSelector((state) => state.expert.slot);
  const selectDay = useSelector((state) => state.expert.day);
  const availabilityLoading = useSelector((state) => state.expert.availabilityLoading);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [dayActive, setDayActive] = useState(today);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState();
  const [slotAvail, setSlotAvail] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.avail) {
      for (let [key, value] of Object.entries(props.avail)) {
        let d = new Date(key);
        d.setHours(0, 0, 0, 0);
        if (d.getTime() === dayActive.getTime()) {
          setSlotAvail(value);
        }
      }
    }
  }, [props.avail, dayActive]);

  const selectNewSlot = (day, newSlot) => {
    props.click();
    dispatch(actions.selectSlot(day, newSlot));
  };

  // Create first day of the week (result ever monday)
  useEffect(() => {
    let firstDayOfWeek = getDateOfISOWeek(
      props.currentWeek[1],
      props.currentWeek[0]
    );
    setFirstDayOfWeek(firstDayOfWeek);
  }, []);

  return (
    <div className="ExpertBook">
      <Calendar
        firstDayOfWeek={firstDayOfWeek}
        dayActive={dayActive.getDate()}
        today={today}
        changeDayActive={(newDay) => setDayActive(newDay)}
      />

      {availabilityLoading ? (
        <Spinner />
      ) : (
        <>
          {slotAvail.filter((x) => x.free === true).length > 0 ? (
            <AvailableSlot
              day={dayActive}
              selectDay={selectDay}
              slotAvail={slotAvail}
              slot={slot}
              click={(day, newSlot) => selectNewSlot(day, newSlot)}
            />
          ) : (
            <p className="no-slot">Nessuna disponibilità per questo giorno.</p>
          )}
        </>
      )}
    </div>
  );
};

export default ExpertBook;
