import React, { useState, useEffect } from "react";
import "./Expert.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ExpertBook from "./ExpertBook/ExpertBook";
import { AvailableFormat, getHourFormat } from "./../../../Helpers/DateFormat";

const Expert = (props) => {
  const [isSelected, setIsSelected] = useState(false);

  const isSelectedHandler = () => {
    props.click();
    setIsSelected(true);
  };

  useEffect(() => {
    if (props.expert?.id === props.el.id) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [props.expert]);

  const isDeselectedHandler = () => {
    props.deselect();
    setIsSelected(false);
  };

  if (props.isSelectable || isSelected) {
    return (
      <div
        className={`Expert ${props.isSelectable ? "c-pointer" : ""}`}
        onClick={props.isSelectable ? () => isSelectedHandler() : null}
      >
        {isSelected ? (
          <div className="expert-back">
            <div className="c-pointer" onClick={() => isDeselectedHandler()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>Indietro</span>
            </div>
          </div>
        ) : null}
        <div className="expert-profile">
          {props.el.profile ? <img src={props.el.profile} alt="" /> : null}
        </div>
        <div className="expert-info">
          <h3>{props.el.name}</h3>
          <p className="expert-on">Esperto in {props.el.job_title}</p>
          {!isSelected ? (
            <p className="availability">
              Prima disponibilità:
              <br />
              {`${AvailableFormat(
                new Date(props.el.first_available_slot.date_time)
              )} alle ${getHourFormat(
                new Date(props.el.first_available_slot.date_time)
              )}`}
            </p>
          ) : null}
        </div>
        {props.isSelectable ? (
          <div className="expert-arrow">
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        ) : null}
        {isSelected ? (
          props.expert?.id === props.el.id ? (
            <ExpertBook
              avail={props.availability}
              expert={props.expert}
              click={props.clickSlot}
              currentWeek={props.currentWeek}
            />
          ) : null
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default Expert;
